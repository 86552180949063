import React, { useContext, useEffect, useRef, useState } from "react";

import { Chip, Divider, Drawer, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import Add from "../../../../images/Add.svg";
import Avatar from "../../../../images/Avatar.svg";

import { useLazyQuery, useMutation } from "@apollo/client";

import dayjs from "dayjs";
import CreateFeed from "./Create";
import More from "../../../../images/DownArrow.svg";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";

import { useStyles } from "../../../styles/TooltipStyles";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import { attendanceOverViewStyles } from "../../../../styles/DrawerStyles";
import {
  GetChannelMessagesData,
  GetChannelMessagesVars,
  GetMessageData,
  GetMessageDataVariables,
  MessageEdge,
} from "./Types";
import {
  ChannelQueryType,
  DEFAULT_TIME,
  Direction,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import { msgType } from "../../../../utils/Types";
import {
  GetChannelMessages,
  GetMessageDataById,
} from "../../../../queries/chat/feeds/list";
import { DeleteChannelMessage } from "../../../../queries/chat/mutations";
import { getFileName, toIsoDate } from "../../../../utils/UtilFunctions";
import {
  EMPTY_STRING,
  ImageTypes,
  ROWS_PER_PAGE,
  licenseTypes,
} from "../../../../utils/constants";
import { Button } from "../../../../stories/Button/Button";
import MessageModal from "../../../../utils/MessageModal";
import LoadingModal from "../../../Modals/LoadingModal";
import useInstDetails from "../../../../customhooks/useInstDetails";
import Navbar from "../../../Layouts/Navbar";
import { Title } from "../../../../stories/Title/Title";
import Sidebar from "../../../Layouts/Sidebar";
import useChannels from "../CustomHooks/useChannels";
import DeleteModal from "../../../Modals/DeleteModal";
import useEmpDetailsById from "../../../../customhooks/useEmpDetailsById";

export interface MessageData {
  url: string;
  mediaContent: string;
  id: number;
  createdTime: string;
  downloadKey: string;
  sent_user_id: number;
}
const Feeds = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { instId } = useParams();
  const tooltipclasses = useStyles();
  const { user_details } = useLoggedInUserDetails();

  const drawerClasses = attendanceOverViewStyles();
  const [createFeed, setCreateFeed] = useState(false);
  const messageListRef = useRef<HTMLUListElement>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);

  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [messageId, setMessageId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const [userList, setUserList] = useState<Map<number, string>>(new Map());
  const { InstFormData } = useInstDetails();
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(true);

  const { data } = useChannels(ChannelQueryType.MYCAMPUSFEED);
  const { employeeFormData } = useEmpDetailsById(true);

  const channelId =
    data && data.GetChannels.length ? data.GetChannels[0].id : 0;

  const [DeleteMessage, { loading: DeleteLoading }] = useMutation(
    DeleteChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetMessageUser, { data: SenderData }] = useLazyQuery<
    GetMessageData,
    GetMessageDataVariables
  >(GetMessageDataById, {
    variables: {
      token,
      ids: allMessages.map((res) => res.node.sent_user_id),
    },
  });
  const [GetMessages, { data: MessagesData, fetchMore, loading }] =
    useLazyQuery<GetChannelMessagesData, GetChannelMessagesVars>(
      GetChannelMessages
    );

  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://hnlm1fiypb.execute-api.ap-south-1.amazonaws.com/downloadObjectFromESandeshBucket?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        if (response.data !== null) {
          axios.get(response.data, { responseType: "blob" }).then(() => {
            setDownloadUrl((prevDownloadUrl) =>
              prevDownloadUrl.map((messageData) => {
                if (getFileName(mediaContent) === messageData.downloadKey) {
                  return { ...messageData, url: response.data };
                } else return messageData;
              })
            );
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const HandleDelete = (id: Number) => {
    DeleteMessage({
      variables: {
        token,
        msg_id: id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetChannelMessages,
          variables: {
            token,
            inst_id: instId ? instId : state.InstId.toString(),
            input: {
              query_type: MsgChannelType.MY_CAMPUS_FEEDS,
              channel_id: channelId,
              start_date: toIsoDate(DEFAULT_TIME),
              end_date: toIsoDate(DEFAULT_TIME),
            },
            direction: Direction.ASC,
            last: 50,
            after: null,
            sortBy: SortBy.CREATED_AT,
            messageContent: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Message Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
    setDeleteModal(!deleteModal);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;

    if (scrollTop === 0 && !loading && hasPreviousPage) {
      fetchMore({
        variables: {
          last: ROWS_PER_PAGE,
          before: startCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.GetChannelMessages.edges;
          const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
          setStartCursor(pageInfo.startCursor);
          setHasPreviousPage(pageInfo.hasPreviousPage);

          const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
            ({ node: { id } }) =>
              newEdges.findIndex(({ node: { id: newId } }) => newId === id) !==
              -1
          );

          if (duplicateCheck.length > 0) return prevResult;

          return {
            GetChannelMessages: {
              edges: [...newEdges, ...allMessages],
              pageInfo,
              totalCount: MessagesData?.GetChannelMessages.totalCount!,
            },
          };
        },
      });
    }
  };

  const handleFileDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }
  };
  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };
  const byDate: { [key: string]: MessageEdge[] } | undefined =
    allMessages &&
    allMessages?.reduce(
      (obj: { [key: string]: MessageEdge[] }, item: MessageEdge) => {
        const date = new Date(item?.node?.created_at);
        const dateString = dayjs(date).format("DD/MM/YY");
        if (obj[dateString]) {
          obj[dateString].push(item);
          return obj;
        }

        obj[dateString] = [{ ...item }];
        return obj;
      },
      {}
    );
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (channelId && token) {
      GetMessages({
        variables: {
          token,
          inst_id: instId ? instId : state.InstId.toString(),
          input: {
            query_type: MsgChannelType.MY_CAMPUS_FEEDS,
            channel_id: channelId,
            start_date: toIsoDate(DEFAULT_TIME),
            end_date: toIsoDate(DEFAULT_TIME),
          },
          direction: Direction.ASC,
          last: 50,
          after: null,
          sortBy: SortBy.CREATED_AT,
          messageContent: EMPTY_STRING,
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
        }
      });
    } // eslint-disable-next-line
  }, [channelId, token, MessagesData, GetMessages]);

  useEffect(() => {
    const messageDataArray: MessageData[] = allMessages
      .filter((mess) => mess.node.msg_media_content !== "")
      .flatMap((message) => {
        const mediaContentArray = message.node.msg_media_content.split(",");
        if (mediaContentArray.length > 1) {
          return mediaContentArray.map((content, index) => {
            const mediaContent = `${
              InstFormData.inst_name
            }/channels/${channelId}/${message.node.id}_${index}.${
              content.split(".")[content.split(".").length - 1]
            }`;
            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,

              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        } else {
          return mediaContentArray.map((content) => {
            const mediaContent = `${
              InstFormData.inst_name
            }/channels/${channelId}/${message.node.id}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,

              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        }
      });
    setDownloadUrl(messageDataArray);
    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        const mediaContent = message.mediaContent;

        getDownloadUrl(message.id, mediaContent);
      });
    };
    fetchDownloadUrls();
  }, [allMessages, createFeed]);

  useEffect(() => {
    if (channelId && token) {
      GetMessageUser().then(({ data }) => {
        if (data) {
          setUserList(
            new Map(
              data.nodes.map((res) => {
                if (res) {
                  if (res.first_name)
                    return [
                      res.id,
                      `${
                        res.first_name +
                        " " +
                        res.middle_name +
                        " " +
                        res.last_name
                      }`,
                    ];
                  else if (res.emp_name) return [res.id, `${res.emp_name}`];
                  else return [res.id, `${res.std_name}`];
                } else return [0, ""];
              })
            )
          );
        }
      });
    }
  }, [channelId, token, SenderData, GetMessageUser]);

  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="feeds">
        <Sidebar />
        <div className="feeds__frame row g-0 justify-content-center">
          <Title>Mycampus Activities</Title>
          <div className="feeds__data col-6">
            <div className="feeds__data--list" onScroll={handleScroll}>
              <div className="feeds__data--list--content">
                <ul ref={messageListRef}>
                  {Object.entries(byDate)?.map(([date, messages]) => (
                    <React.Fragment key={date}>
                      <Divider>
                        <Chip label={date} />
                      </Divider>
                      {messages.map((message, index) => {
                        const parsedContent = parseMessageContent(
                          message.node.msg_content
                        );
                        const parsedContentHeader = parseMessageContent(
                          message.node.msg_header
                        );
                        const isImage = ImageTypes.includes(
                          message.node.msg_media_content.split(".")[
                            message.node.msg_media_content.split(".").length - 1
                          ]
                        );

                        const isPdf =
                          message.node.msg_media_content.includes(".pdf");
                        const isExcel =
                          message.node.msg_media_content.includes(".xls") ||
                          message.node.msg_media_content.includes(".xlsx");
                        const isVideo =
                          message.node.msg_media_content.includes(".mp4");
                        const id = state.claims?.EMPLOYEE
                          ? state.empLoginId
                          : state.studentId;
                        const isSentUser = id === message.node.sent_user_id;

                        const combinedById = downLoadUrl.reduce(
                          (result, item) => {
                            if (!result[item.id]) {
                              result[item.id] = [item];
                            } else {
                              result[item.id].push(item);
                            }
                            return result;
                          },
                          {} as { [id: number]: MessageData[] }
                        );

                        return (
                          <li key={index} className="feeds__messages--list">
                            {!isSentUser && (
                              <img
                                src={Avatar}
                                alt="/"
                                className="feeds__messages--list--image"
                              />
                            )}
                            <div className="feeds__messages--list--sender">
                              <div className="feeds__messages--list--flex">
                                <b className="feeds__messages--list--name">
                                  {!isSentUser && (
                                    <>
                                      {userList.get(message.node.sent_user_id)}
                                    </>
                                  )}
                                </b>

                                {employeeFormData.can_send_feeds ? (
                                  <Tooltip
                                    onClick={() =>
                                      setEnableToolTipModal(!enableToolTipModal)
                                    }
                                    classes={{
                                      tooltip: tooltipclasses.studentOptions,
                                    }}
                                    placement="right"
                                    title={
                                      enableToolTipModal && (
                                        <>
                                          <ul className="feeds__messages--list--tooltip--ul">
                                            <li
                                              onClick={() => {
                                                setOperation(Operation.UPDATE);
                                                setMessageId(message.node.id);
                                                setCreateFeed(!createFeed);
                                              }}
                                              className="studentlist__table--more--fee"
                                            >
                                              <img src={Edit} alt="/" />
                                              <span> Edit Message</span>
                                            </li>
                                            <li
                                              onClick={() => {
                                                setDeleteModal(!deleteModal);
                                                setDeleteId(message.node.id);
                                              }}
                                              className="studentlist__table--more--fee"
                                            >
                                              <img src={Delete} alt="/" />
                                              <span> Delete Message</span>
                                            </li>
                                          </ul>
                                        </>
                                      )
                                    }
                                  >
                                    <span className="feeds__messages--list--time">
                                      {dayjs(message.node.created_at).format(
                                        "LT"
                                      )}
                                      <img src={More} alt="/" />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <span className="feeds__messages--list--time">
                                    {dayjs(message.node.created_at).format(
                                      "LT"
                                    )}
                                  </span>
                                )}
                              </div>

                              {message.node.msg_header && (
                                <b
                                  className="feeds__messages--list--header"
                                  dangerouslySetInnerHTML={{
                                    __html: parsedContentHeader,
                                  }}
                                />
                              )}
                              {message.node.msg_content && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: parsedContent,
                                  }}
                                />
                              )}

                              {combinedById[message.node.id]?.map(
                                ({ mediaContent, url }) => {
                                  if (url) {
                                    if (isImage) {
                                      return (
                                        <a key={url} href={url}>
                                          <img
                                            src={url}
                                            alt="/"
                                            className="feeds__messages--file-image"
                                          />
                                        </a>
                                      );
                                    } else if (isPdf || isExcel) {
                                      return (
                                        <a
                                          key={url}
                                          href={url}
                                          onClick={() =>
                                            handleFileDownload(url)
                                          }
                                        >
                                          {getFileName(mediaContent)}
                                        </a>
                                      );
                                    } else if (isVideo) {
                                      return (
                                        <video
                                          key={url}
                                          width="320"
                                          height="240"
                                          controls
                                          src={url}
                                        >
                                          {mediaContent}
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                }
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
            <Button
              onClick={() => {
                setCreateFeed(!createFeed);
                setOperation(Operation.CREATE);
              }}
              disabled={employeeFormData.can_send_feeds === false}
            >
              <img src={Add} alt="" />
              Create New Feed
            </Button>
          </div>
        </div>
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={createFeed}
        onClose={() => setCreateFeed(!createFeed)}
      >
        <CreateFeed
          setModalFlag={setCreateFeed}
          operation={operation}
          messageId={messageId}
          setMessage={setMessage}
        />
      </Drawer>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <LoadingModal flag={DeleteLoading} />
    </>
  );
};

export default Feeds;
